export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    document.addEventListener( 'wpcf7mailsent', function( event ) {
        console.log('Sending conversion event Updated', event);
        window.gtag('event', 'conversion', {'send_to': 'AW-10884794793/H3jwCJ7z_bIDEKmTo8Yo'});
        window.gtag('event', 'conversion', {'send_to': 'AW-10884877250/Ahs2CK3i6L8DEMKXqMYo'});
        window.gtag('event', 'conversion', {'send_to': 'AW-10884796428/cFVjCPmUwYMYEIygo8Yo'});
        window.gtag('event', 'contact_form_submission', {
          'event_category' : 'contact',
          'event_label' : 'Contact Form Submission',
        });
        window.fbq('track', 'Contact');
    }, false );
  },
};
